<template>
  <div class="mx-auto px-4 sm:px-4 lg:px-8">
    <BaseCard title="Penerimaan Barang dari Supplier">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <div class="ounded-md relative shadow-sm">
            <div>
              <select
                v-model="filter.is_received"
                @change="onReload"
                class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Diterima</option>
                <option :value="false">Belum diterima</option>
              </select>
            </div>
          </div>
          <base-search v-model="filter.search" @input="onReloadDebounce" />
        </div>
        <div class="mt-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getStockMovements.meta.page.total"
              :perPage="getStockMovements.meta.page.perPage"
              :currentPage="getStockMovements.meta.page.currentPage"
              :meta="getStockMovements.meta"
              cursor
              @pagechanged="onPageChanged"
              :columns="[
                {
                  name: 'Nomor Kemasan',
                },
                {
                  name: 'Total Produk',
                  theadClass: 'text-right px-6 py-4 pr-24',
                },
                {
                  name: 'Total Berat',
                  theadClass: 'text-right px-6 py-4 pr-24',
                },
                {
                  name: 'Status',
                  theadClass: 'text-center',
                },
              ]"
            >
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getStockMovements.data?.length > 0">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getStockMovements.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click.prevent="onViewStockMovement(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <div class="text-sm font-bold text-gray-900">
                        {{ getStockMovementIncluded(data).attributes.code }} /
                        {{
                          getStockMovementIncluded(data).attributes.order_code
                        }}
                      </div>
                      <div class="text-xs font-light text-gray-300">
                        {{
                          dayjs(
                            getStockMovementIncluded(data).attributes.updatedAt
                          ).format('ll LT')
                        }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                    >
                      {{
                        Object.keys(
                          getStockMovementIncluded(data).attributes
                            .product_summaries
                        ).length
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                    >
                      {{
                        countStockMovementWeight(getStockMovementIncluded(data))
                          | toCurrency
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        v-if="
                          getStockMovementIncluded(data).attributes.is_received
                        "
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Diterima
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum diterima
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="8"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <stock-movement-modal
          :visible="visibleDetail"
          description="Data penerimaan barang dari Supplier"
          :with-dispute="false"
          with-detail-batch
          force-display-first-packet
          @close="visibleDetail = false"
          @refresh="onRefresh"
        >
          <template #origin-office="{ originOffice }">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nomor Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ originOffice.attributes.code }}
              </dd>
            </div>
          </template>
        </stock-movement-modal>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import { sum } from '@/utils/array';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import BaseSearch from '@/components/base/Search.vue';
import { stockMovementRead } from '@/mixins/stock-movement/stock-movement-read.mixin';

export default {
  name: 'Home',
  components: {
    Datatable,
    StockMovementModal,
    BaseSearch,
  },
  mixins: [stockMovementRead],

  data() {
    return {
      visibleDetail: false,
      page: {
        number: 1,
        size: 5,
      },
      filter: {
        is_received: false,
        search: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
    }),
    readStockMovementsParams() {
      return {
        include: 'stock-movement',
        'fields[simple-stock-movements]': 'stock-movement',
        'fields[stock-movements]':
          'code,order_code,updatedAt,product_summaries,packets,is_received',
        'pagination[type]': 'cursor',
        'page[limit]': 5,
        'filter[is_received]': this.filter.is_received,
        'filter[search]': this.filter.search,
        'filter[is_shipped]': true,
        'filter[origin_office_category_id]': 6,
        'filter[destination_office_category_id]': 1,
      };
    },
    viewStockMovementParams() {
      return {
        'fields[stock-movements]':
          'order,origin-office,product_summaries,product-batches,packets,code,updatedAt,is_received,is_shipped,is_valid_for_shipment',
        'fields[orders]': 'origin_code,createdAt',
        include: 'order,origin-office,product-batches',
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    onStockMovementViewed() {
      this.visibleDetail = true;
    },
    onRefresh() {
      this.onReload();

      this.visibleDetail = false;
    },

    countStockMovementWeight(stockMovement) {
      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.total_weight);
      });
    },
  },
  created() {
    this.onCreated();
  },
};
</script>
